import React, { createElement, Fragment, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "@emotion/styled";
import type { PageProps } from "gatsby";

import backgroundColourAtom from "../../atoms/backgroundColour";
import langAtom from "../../atoms/lang";
import ProjectBadge from "../../components/ProjectBadge";
import dayjs from "../../utils/dayjs";
import idToColour from "../../utils/idToColour";
import isLight from "../../utils/isLight";
import i18n from "../../utils/i18n";
import InfoIcon, { IconTypes } from "../../components/InfoIcon";
import breakpoints from "../../utils/breakpoints";
import Carousel from "../../components/Carousel";
import Tag from "../../components/Tag";

type StyleProps = {
  isLight: boolean;
};

const ProjectPageStyles = styled.div<StyleProps>`
  margin: 1rem;
  color: ${(props) => (props.isLight ? "black" : "white")};

  .project-details {
    display: flex;
    max-width: ${breakpoints.values.lg}px;
    margin: auto;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
  }

  .project-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    justify-content: center;
    margin: 16px;
  }

  .project-info {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.5rem 2rem;

    ${breakpoints.media.md} {
      grid-template-columns: auto;
    }

    padding: 16px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 0px 12px 12px -12px black, 0px 0px 12px -6px black;

    svg {
      margin-right: 6px;
    }

    .bold {
      font-weight: bold;
    }
  }
`;

type DataProps = {
  sanityProject: {
    _createdAt: string;
    _updatedAt: string;
    _id: string;
    project_id: {
      current: string;
    };
    name: string;
    description_en: string;
    description_ja: string;
    icon: string | null;
    links: {
      type: string;
      url: string;
    }[];
    screenshots: string[];
    embed: string | null;
    video: string | null;
    tags: {
      tag_id: {
        current: string;
      };
      label_en: string;
      label_ja: string;
    }[];
  };
};

export default function ProjectPage(props: PageProps<DataProps>) {
  const {
    location: pageLocation,
    data: { sanityProject: project },
  } = props;

  const colour = idToColour(project.project_id.current);
  const [siteLang] = useRecoilState(langAtom);
  const setBgColour = useSetRecoilState(backgroundColourAtom);
  const lang = useRecoilValue(langAtom);
  const locale = lang === "en" ? "en-gb" : lang;

  useEffect(() => {
    setBgColour(() => colour);
    if (pageLocation.pathname != window.location.pathname) {
      setBgColour(() => "");
    }
  });

  return (
    <ProjectPageStyles isLight={isLight(colour)}>
      <div className="project-details">
        <ProjectBadge
          project_id={project.project_id.current}
          name={project.name}
          icon={project.icon}
        />
        <h1>{project.name}</h1>
        <div className="project-tags">
          {project.tags.map((tag) => (
            <AniLink
              fade
              to={`/tags/${tag.tag_id.current}`}
              key={tag.tag_id.current}
            >
              <Tag
                label={siteLang === "ja" ? tag.label_ja : tag.label_en}
                id={tag.tag_id.current}
              />
            </AniLink>
          ))}
        </div>
        <div className="project-info">
          <span>
            <InfoIcon name="created" />
            <span className="bold">{i18n("projects.created")}: </span>
            {dayjs(project._createdAt).locale(locale).format("LL")}
          </span>
          <span>
            <InfoIcon name="updated" />
            <span className="bold">{i18n("common.updated")}: </span>
            {dayjs(project._createdAt).locale(locale).format("LL")}
          </span>
          {project.links.map((link, i) => (
            <span key={i}>
              <InfoIcon name={link.type as IconTypes} />
              <span className="bold">
                {i18n(`projects.link_${link.type}`)}:{" "}
              </span>
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                {
                  link.url.replace(
                    /([-/_.])/g,
                    "$&​",
                  ) /* Zero-width space for breaking */
                }
              </a>
            </span>
          ))}
        </div>
        <p>{lang == "ja" ? project.description_ja : project.description_en}</p>
        <Carousel images={project.screenshots} />
      </div>
    </ProjectPageStyles>
  );
}

export const query = graphql`
  query ($id: String) {
    sanityProject(id: { eq: $id }) {
      _createdAt
      _updatedAt
      _id
      project_id {
        current
      }
      name
      description_en
      description_ja
      icon
      links {
        type
        url
      }
      screenshots
      embed
      video
      tags {
        tag_id {
          current
        }
        label_en
        label_ja
      }
    }
  }
`;

export { default as Head } from "../../core/Head";
