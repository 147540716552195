import React, { useState } from "react";
import styled from "@emotion/styled";
import idToColour from "../utils/idToColour";
import isLight from "../utils/isLight";
import Image from "./Image";
import { css } from "@emotion/react";

interface CarouselProps {
  images: string[];
}

const CarouselStyles = styled.div<CarouselProps>`
  display: flex;
  position: relative;

  .carousel-images {
    width: 100%;
    border-radius: 20px;

    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    background-color: black;
    overflow: hidden;
  }

  button {
    position: absolute;
    top: calc(50% - 40px);
    z-index: 10;

    width: 80px;
    height: 80px;
    border: none;
    border-radius: 80px;

    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;

    &:hover,
    &:focus {
      background-color: #0000001f;
    }

    &.left {
      left: 10px;
    }
    &.right {
      right: 10px;
    }
  }

  figure {
    grid-area: 1 / 1 / 2 / 2;
    height: 100%;
    transition: 0.5s;

    img {
      width: 100%;
    }
  }
`;

function Carousel(props: CarouselProps & React.HTMLProps<HTMLDivElement>) {
  const { images } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <CarouselStyles className="carousel" {...props}>
      <button
        className="left"
        onClick={() =>
          setCurrentIndex((currentIndex + 1 + images.length) % images.length)
        }
      >
        <svg width="75" height="75">
          <path
            d="M45 20 L25 37.5L45 55"
            stroke="white"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </button>
      <button
        className="right"
        onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}
      >
        <svg width="75" height="75">
          <path
            d="M30 20L50 37.5L30 55"
            stroke="white"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </button>
      <div className="carousel-images">
        {images.map((image, i) => (
          <Image
            key={image}
            src={image}
            fit="contain"
            css={css`
              opacity: ${i == currentIndex ? 1 : 0};
              z-index: ${i == currentIndex ? 5 : 0};
            `}
          />
        ))}
      </div>
    </CarouselStyles>
  );
}

export default Carousel;
